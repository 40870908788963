import React from 'react'
import '../assets/Empty.css'

export const Empty = () => {
  return (
    <div className='loading-container'>
      <div className='spinner'></div>
    </div>
 )
}
